import Select from "react-select";
import type { FunctionComponent } from "react";
import type { FieldRenderProps } from "react-final-form";
import type { StylesConfig } from "react-select";

type MyOptionType = {
  label: string;
  value: string | number | Date;
};

type IsMulti = false;

const SingleSelect: FunctionComponent<FieldRenderProps<string, HTMLElement>> =
  ({
    input,
    meta,
    label,
    placeholder,
    options,
    disabled,
    helperText,
    noOptionsMessage,
    isSearchable,
  }) => {
    const selectStyle: StylesConfig<MyOptionType, IsMulti> = {
      control: (provided) => ({
        ...provided,
        borderWidth: 0,
        background: "white",
        paddingTop: "5px",
        paddingBottom: "5px",
        paddingLeft: "1rem",
        paddingRight: "0.4rem",
        outline: "none",
        borderColor: "#bdbdbd",
        cursor: "pointer",
      }),
      menu: (provided) => ({
        ...provided,
        zIndex: 99999,
        overflowY: "scroll",
      }),
      input: (provided) => ({
        ...provided,
        height: "auto",
        textShadow: "0 0 0 #2196f3",
      }),
      placeholder: (provided) => ({
        ...provided,
        color: "#616161",
      }),
      singleValue: (provided) => ({
        ...provided,
        paddingTop: "16px",
        marginLeft: "0px",
        color: "#616161",
      }),
      indicatorSeparator: (provided) => ({
        ...provided,
        display: "none",
      }),
      valueContainer: (provided) => ({
        ...provided,
        padding: "4px 0px",
      }),
    };
    return (
      <div className="relative">
        <div className={input.value !== "" ? "is-filled" : ""}>
          <label
            className="absolute text-xxs inline-block opacity-0 text-black px-4 top-0 cursor-auto mt-2"
            htmlFor={`field-${input.name}`}
          >
            {label}
          </label>
          <Select
            instanceId={`field-${input.name}`}
            className={`w-full border rounded-md relative ${
              meta.error && meta.touched && "border-red-600 bg-red-100"
            }`}
            styles={selectStyle}
            placeholder={placeholder}
            options={options}
            onFocus={input.onFocus}
            onBlur={input.onBlur}
            onChange={(v) => {
              input.onChange(v.value);
            }}
            value={options.find((a: MyOptionType) => a.value === input.value)}
            defaultValue={options.find(
              (a: MyOptionType) => a.value === input.value
            )}
            isDisabled={disabled}
            noOptionsMessage={() => noOptionsMessage || "No options"}
            isSearchable={isSearchable}
          />
        </div>
        {meta.touched && meta.error && (
          <p className="mt-1 text-xs text-red-600">{meta.error}</p>
        )}
        {helperText && (
          <p className="mt-2 text-sm text-gray-1060 leading-tight pl-4">
            {helperText}
          </p>
        )}
        <style jsx>
          {`
            label {
              transition: all 200ms ease-in;
              transform: translate3d(0, 0.25rem, 0);
            }
            input {
              transition: all 200ms ease-out;
              box-sizing: border-box;
              border-color: #bdbdbd;
            }
            .is-filled input {
              padding-top: 1.25rem;
            }
            .is-filled label {
              opacity: 1;
              transform: translate3d(0, 0, 0);
              z-index: 10;
              pointer-events: none;
            }
          `}
        </style>
      </div>
    );
  };

export const NewSingleSelect: FunctionComponent<FieldRenderProps<string, HTMLElement>> =
  ({
    input,
    meta,
    label,
    placeholder,
    options,
    disabled,
    helperText,
    noOptionsMessage,
    isSearchable,
  }) => {
    const selectStyle: StylesConfig<MyOptionType, IsMulti> = {
      control: (provided, state) => ({
        ...provided,
        borderWidth: 0,
        background: "white",
        outline: "none",
        borderColor: "#bdbdbd",
        cursor: "pointer",
        borderRadius: state.isFocused ? 0 : undefined,
      }),
      menu: (provided) => ({
        ...provided,
        zIndex: 99999,
        overflowY: "scroll",
      }),
      option: (provided) => ({
        ...provided,
        height: "56px",
        display: "flex",
        alignItems: "center",
      }),
      input: (provided) => ({
        ...provided,
        textShadow: "0 0 0 #2196f3",
      }),
      placeholder: (provided) => ({
        ...provided,
        color: "#616161",
        paddingTop: meta.active || (meta.touched && meta.error) ? "12px" : undefined,
      }),
      singleValue: (provided) => ({
        ...provided,
        marginLeft: "0px",
        color: "#616161",
        paddingTop: meta.active || meta.touched ? "12px" : undefined,
        paddingBottom: meta.submitFailed && !meta.active ? "12px" : undefined,
      }),
      indicatorSeparator: (provided) => ({
        ...provided,
        display: "none",
      }),
      valueContainer: (provided) => ({
        ...provided,
        marginLeft: "102px",
        height: "56px",
      }),
    };

    return (
      <div className="relative">
        <div className={input.value !== "" ? "is-filled" : ""}>
          <label
            className="absolute z-10 flex items-center text-[15px] h-[56px] top-[1px] left-[1px] bg-[#E9E8FA] font-bold text-[#333333] px-[28px] cursor-auto"
            htmlFor={`field-${input.name}`}
          >
            {label}
          </label>
          <Select
            instanceId={`field-${input.name}`}
            className={`w-full border relative ${
              meta.error && meta.touched && "border-red-600 bg-red-100"
            }`}
            styles={selectStyle}
            placeholder={placeholder}
            options={options}
            onFocus={input.onFocus}
            onBlur={input.onBlur}
            onChange={(v) => {
              input.onChange(v.value);
            }}
            value={options.find((a: MyOptionType) => a.value === input.value)}
            defaultValue={options.find(
              (a: MyOptionType) => a.value === input.value
            )}
            isDisabled={disabled}
            noOptionsMessage={() => noOptionsMessage || "No options"}
            isSearchable={isSearchable}
          />
        </div>
        {meta.touched && meta.error && (
          <p className="mt-1 text-xs text-red-600">{meta.error}</p>
        )}
        {helperText && (
          <p className="mt-2 text-sm text-gray-1060 leading-tight pl-4">
            {helperText}
          </p>
        )}
        <style jsx>
          {`
            label {
              transition: all 200ms ease-in;
            }
            input {
              transition: all 200ms ease-out;
              box-sizing: border-box;
              border-color: #bdbdbd;
            }
          `}
        </style>
      </div>
    );
  };

export default SingleSelect;
